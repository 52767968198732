import React from "react"

import Layout from "../components/layout/layout"
import Seonew from "../components/seo-new"
import HomeHeroSection from "../components/sections/home-hero-section"
import HomeAboutSection from "../components/sections/home-about-section"
import HomeAboutSraSection from "../components/sections/home-about-sra-section"

const IndexPage = () => (
  <Layout>
    <Seonew />

    <HomeHeroSection />

    <HomeAboutSection />

    <HomeAboutSraSection />
  </Layout>
)

export default IndexPage
