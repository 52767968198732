import React from 'react'
import { VimeoPlayer } from 'reactjs-vimeo-player'

const HomeAboutSraSection = () => (
  <div className="bg-gray-100 pt-12 md:pt-24 pb-12 md:pb-24 border-b-4 border-gray-300 px-4">
    <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

      <h2 className="text-gray-900 text-4xl md:text-5xl text-center font-extrabold tracking-wider">About SRA 831(b) Admin</h2>

      <div className='w-11/12 pt-8'>
        <p className="text-gray-900 text-xl md:text-2xl mt-6">SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies.</p>
      </div>

      <h2 className="text-gray-900 text-4xl md:text-5xl text-center font-extrabold tracking-wider mt-16">Mission</h2>

      <div className='w-11/12 pt-8'>
        <p className="text-gray-900 text-xl md:text-2xl mt-6">At SRA we believe small and mid-size businesses are the lifeblood of the American economy. The 831(b) Plans we administrate are meant to ensure these businesses weather the storm.</p>

        <p className="text-gray-900 text-xl md:text-2xl mt-6">We care deeply about the financial security of our clients and their businesses. Our talented and motivated team strives to provide creative, quality, well-designed plans to address the varying needs of the clients we serve.</p>
      </div>

      <div className="w-full max-w-screen-md mt-16">
        <VimeoPlayer id="566776937" width={640} height={480} responsive />
      </div>

      <div className="mt-16">
        <a
          href="https://www.831b.com/831b-plan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="w-full mb-8 lg:mb-0 md:w-auto bg-red-900 hover:bg-red-800 text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
            Learn More
          </button>
        </a>
      </div>
    </div>
  </div>
)

export default HomeAboutSraSection