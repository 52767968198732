import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from '../../utils/htmlSerializer';

const HomeAboutSection = () => {
  const data = useStaticQuery(graphql`
    query HomeAboutSectionQuery {
      prismicHomepage {
        data {
          title
          description {
            richText
          }
        }
      }
    }
  `)

  const doc = data.prismicHomepage

  return (
    <div className="bg-gray-100 pt-12 md:pt-24 pb-12 md:pb-24 border-b-4 border-gray-300 px-4">
      <div className="w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-xl flex flex-col items-center mx-auto">

        <h2 className="text-gray-900 text-4xl md:text-5xl text-center font-extrabold tracking-wider">{doc.data.title}</h2>

        <div className='w-11/12 pt-8'>
          <div className="text-gray-900 text-xl md:text-2xl mt-6">
            <RichText
              render={doc.data.description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>

      </div>
    </div>
  )
}

export default HomeAboutSection