import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const HomeHeroSection = () => {
  const data = useStaticQuery(graphql`
    query HomeHeroSectionQuery {
      prismicHomepage {
        data {
          subheadline
        }
      }
    }
  `)

  const doc = data.prismicHomepage

  return (
    <div className="bg-gray-100 flex justify-center items-center border-b-4 border-gray-300 px-4 pt-24 pb-24">
      <div className='w-full max-w-screen-xl flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-full md:w-10/12 xl:w-2/3 flex flex-col items-center mx-auto'>
          <div>
            <StaticImage
              src="../../images/fmr-logo-new.png"
              width={300}
              quality={95}
              alt="FMR Logo"
            />
          </div>

          <h1 className='text-4xl md:text-5xl font-medium text-gray-900 text-center uppercase mt-6'>
            <span className='leading-tight'>FMR is An accredited advisor of SRA 831(b) Admin</span>
          </h1>

          <h2 className='w-full lg:w-9/12 text-xl lg:text-2xl text-gray-900 text-center mt-6'>{doc.data.subheadline}</h2>
        </div>
      </div>
    </div>
  )
}

export default HomeHeroSection